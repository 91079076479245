<script lang="ts">
  export let list = [];
  export let endIndex;
  export let startIndex;
</script>

{#each list as media, index}
  {#if (!endIndex && !startIndex) || (endIndex && index < endIndex) || (startIndex && index > startIndex)}
    <a
      class="bg-black-22 hover:bg-black-33 rounded-lg  h-24  items-center flex justify-center "
      href={media.link}
      target="_blank"
    >
      <img
        src={media.img}
        alt={media.title}
        class="w-2/3 mx-auto {startIndex && index === 4
          ? 'sm:h-3/5'
          : 'sm:w-3/5'}"
      />
    </a>
  {/if}
{/each}
