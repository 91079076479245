<script lang="ts">
  import MainLogo from "../components/MainLogo.svelte";
  import { backgroundColor, menuIsOpen } from "../common/Service";
  import { footerIcons } from "../common/Const";
  let status;
  function menuClick() {
    status = !$menuIsOpen;
    menuIsOpen.set(status);
  }
</script>

<header class="w-full h-p72 nft-content {$backgroundColor}">
  <div
    class="flex justify-between items-center md:pt-6 phone:py-6 md:max-w-1440   mx-auto "
  >
    <MainLogo />
    <div class="md:flex md:items-center md:justify-end phone:hidden ">
      <div>
        <a
          class="md:mr-8 font-text cursor-pointer  text-16 hover-animation uppercase"
          href="/growthplan"
        >
          Growth plan
        </a>

        <a
          class="mr-6 font-text cursor-pointer  text-16 hover-animation"
          href="mailto:info@alchemynft.org">CONTACT US</a
        >
        <a
          class="md:mr-8 font-text cursor-pointer  text-16 hover-animation"
          href="/team"
        >
          TEAM
        </a>
      </div>
      <div class="flex ">
        {#each footerIcons as item, i}
          <a
            href={item.url}
            target="_blank"
            class="cursor-pointer  social-icon-{item.id} h-5 w-5 {i + 1 <
            footerIcons.length
              ? 'mr-8'
              : ''} ">&nbsp;</a
          >
        {/each}
      </div>
    </div>
    <div class="md:hidden {$menuIsOpen ? 'menu-open' : ''}">
      <a class="sider-menu" on:click={() => menuClick()}>&nbsp;</a>
    </div>
  </div>
</header>
