export const footerIcons = [
    {
        id: "telegram",
        src: "/images/ic-telegram.svg",
        url: "https://t.me/AlchemyNFT",
    },
    {
        id: "discord",
        src: "/images/ic-discord.svg",
        url: "https://discord.gg/ns5KQHJctp",
    },
    {
        id: "twitter",
        src: "/images/ic-twitter.svg",
        url: "https://twitter.com/AlchemyNFT",
    },
    {
        id: "medium",
        src: "/images/ic-medium.svg",
        url: "https://medium.com/alphawallet/alchemynft/home",
    },
];
export const teamIcons = [
    {
        id: "twitter",
        src: "/images/ic-twitter-gray.svg",
    },
    {
        id: "medium",
        src: "/images/ic-medium-gray.svg",
    },
    {
        id: "linkedin",
        src: "/images/ic-linkedin-gray.svg",
    },
];
