<script lang="ts">
  // import Headr from "./components/Header.svelte";
  // import Sidebar from "./components/Sidebar.svelte";
  // import Footer from "./components/Footer.svelte";
  import Home from "./components/Home.svelte";
  import Team from "./components/Team.svelte";
  import GrowthPlan from "./components/GrowthPlan.svelte";
  import { Route, router } from "tinro";

  router.mode.hash();
</script>

<Route path="/">
  <Home />
</Route>
<Route path="/team"><Team /></Route>
<Route path="/growthplan"><GrowthPlan /></Route>

<style global>
  /* @import "../assets/global.css"; */
  @tailwind base;
  @tailwind components;
  @tailwind utilities;

  body {
    color: white;
    background-color: #000000;
    font-family: "Source Code Pro", monospace;
    text-rendering: geometricPrecision;
  }

  ::-webkit-scrollbar {
    width: 0;
    background: transparent;
  }

  main::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }

  main {
    scrollbar-width: none;
    -ms-overflow-style: none; /* IE 11 */
  }

  .button {
    border-radius: 4px;
    background: radial-gradient(circle, #b700fa 0%, #8100f2 100%);
    box-shadow: 0 4px 8px 0 rgba(11, 34, 99, 0.5);
  }

  .button:disabled {
    background: radial-gradient(circle, #be83d3 0%, #945ec7 100%);
  }

  textarea:focus,
  input:focus {
    outline: none;
  }

  button:active,
  button:focus {
    outline: unset;
  }

  ::-webkit-scrollbar {
    display: none;
  }

  .team-content {
    padding-left: 40px;
  }

  .team-items {
    margin-right: 40px;
  }

  .growth-content {
    padding-right: 40px;
    padding-left: 40px;
  }

  @keyframes loadingmove {
    from {
      transform: rotate(0);
    }

    to {
      transform: rotate(359deg);
    }
  }

  /* Safari and Chrome */
  @-webkit-keyframes loadingmove {
    from {
      transform: rotate(0);
    }

    to {
      transform: rotate(359deg);
    }
  }

  .loading {
    border-radius: 50%;
    animation: loadingmove 1.2s linear infinite;
    -webkit-animation: loadingmove 1.2s linear infinite;
    border-bottom: 3px solid transparent;
  }

  .hero-bg {
    background: linear-gradient(179.55deg, #222222 0%, #151515 100%);
  }
  /* 
  .nft-hero {
    margin-bottom: 80px;
  } */

  .nft-content {
    padding-left: 40px;
    padding-right: 40px;
  }

  /* h1 {
    font-size: 72px;
    font-weight: 300;
    line-height: 86px;
  }

  h2 {
    font-size: 64px;
    font-weight: 300;
    line-height: 77px;
  }

  h3 {
    font-size: 48px;
    font-weight: 300;
    line-height: 58px;
  } */
  h1 {
    font-size: 64px;
    font-weight: 300;
    line-height: 64px;
  }

  h2 {
    font-size: 56px;
    font-weight: 300;
    line-height: 67px;
  }

  h3 {
    font-size: 40px;
    font-weight: 300;
    line-height: 48px;
  }

  .hiring {
    display: block;
  }

  .slide-title {
    gap: 2.5rem;
  }

  .slide-buttoh-bottom {
    display: none;
  }

  .slide-buttoh-top {
    display: block;
  }

  @media (max-width: 769px) {
    .nft-hero {
      margin: 0px 24px 72px 24px;
    }

    .slide-title {
      gap: 1rem;
    }

    .slide-buttoh-top {
      display: none;
    }

    .slide-buttoh-bottom {
      display: grid;
    }
  }
  @media (max-width: 1023px) {
    .team-member {
      grid-template-columns: repeat(2, minmax(0, 1fr)) !important;
    }

    .growth-content {
      padding-right: 24px;
      padding-left: 24px;
    }

    .team-content {
      padding-left: 24px;
    }

    .team-items {
      margin-right: 24px;
    }

    .nft-content {
      padding-left: 24px;
      padding-right: 24px;
    }

    /* .nft-hero {
      margin: 0px 0px 0px 0px;
    } */

    .hiring {
      display: none;
    }

    .hiring-div {
      padding: 81px 0px;
      text-align: center;
      grid-column: span 2 / span 2;
      margin-bottom: 48px;
    }
  }

  .hover-animation:hover {
    transition: all 0.3s;
    --tw-text-opacity: 1;
    color: rgba(184, 125, 255, var(--tw-text-opacity));
  }

  .social-icon-telegram,
  .social-icon-discord,
  .social-icon-medium,
  .social-icon-twitter,
  .social-icon-header-telegram,
  .social-icon-header-discord,
  .social-icon-header-medium,
  .social-icon-header-twitter {
    background-size: 100%;
    background-repeat: no-repeat;
    background-size: contain;
    transition: 0.5s;
    transition-delay: 75ms;
  }

  .social-icon-telegram {
    background-image: url("/images/ic-telegram-light.svg");
  }

  .social-icon-telegram:hover,
  .social-icon-header-telegram:hover {
    background-image: url("/images/ic-telegram-purple.svg");
  }

  .social-icon-discord {
    background-image: url("/images/ic-discord-light.svg");
  }

  .social-icon-discord:hover,
  .social-icon-header-discord:hover {
    background-image: url("/images/ic-discord-purple.svg");
  }

  .social-icon-medium {
    background-image: url("/images/ic-medium-light.svg");
  }

  .social-icon-medium:hover,
  .social-icon-header-medium:hover {
    background-image: url("/images/ic-medium-purple.svg");
  }

  .social-icon-twitter {
    background-image: url("/images/ic-twitter-light.svg");
  }

  .social-icon-twitter:hover,
  .social-icon-header-twitter:hover {
    background-image: url("/images/ic-twitter-purple.svg");
  }

  .social-icon-header-telegram {
    background-image: url("/images/ic-telegram.svg");
  }

  .social-icon-header-discord {
    background-image: url("/images/ic-discord.svg");
  }

  .social-icon-header-medium {
    background-image: url("/images/ic-medium.svg");
  }

  .social-icon-header-twitter {
    background-image: url("/images/ic-twitter.svg");
  }

  .sider-menu {
    display: block;
    position: fixed;
    width: 32px;
    height: 32px;
    background: transparent;
    right: 20px;
    top: 1.22rem;
    z-index: 30;
    pointer-events: all;
  }

  .sider-menu::before {
    content: "";
    display: block;
    height: 2px;
    width: 36px;
    background: #fff;
    left: -6px;
    top: 11px;
    position: absolute;
    transition: all 0.5s;
  }

  .sider-menu::after {
    content: "";
    display: block;
    height: 2px;
    width: 36px;
    background: #fff;
    left: -6px;
    bottom: 11px;
    position: absolute;
    transition: all 0.5s;
  }

  .menu-open .sider-menu::before {
    transform: rotate(-135deg) translate(-3px, -3px);
    background: #fff !important;
  }
  .menu-open .sider-menu::after {
    transform: rotate(135deg) translate(-3px, 3px);
    background: #fff !important;
  }

  @media (max-width: 1023px), (pointer: coarse) {
    canvas {
      height: 75vh;
    }
  }

  canvas {
    width: 100%;
    height: 100vh;
    display: inline-block;
    background: transparent;
  }

  .slide-1,
  .slide-2,
  .slide-3,
  .slide-4,
  .slide-5 {
    background-repeat: no-repeat;
    background-position: right;
  }

  .slide-1 {
    background-image: url("/images/slides/stage-1.svg");
  }

  .slide-2 {
    background-image: url("/images/slides/stage-2.svg");
  }

  .slide-3 {
    background-image: url("/images/slides/stage-3.svg");
  }

  .slide-4 {
    background-image: url("/images/slides/stage-4.svg");
  }

  .slide-5 {
    background-image: url("/images/slides/stage-5.svg");
  }

  .content-bg-left {
    background-position: left !important;
  }

  @media (max-width: 374px) {
    .slide-1,
    .slide-2,
    .slide-3,
    .slide-4,
    .slide-5 {
      background-size: contain;
    }
  }
  .fade-in-slide {
    animation: fadeIn 1.5s;
    -webkit-animation: fadeIn 1.5s;
    -moz-animation: fadeIn 1.5s;
    -o-animation: fadeIn 1.5s;
    -ms-animation: fadeIn 1.5s;
  }
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @-moz-keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @-webkit-keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @-o-keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @-ms-keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
</style>
