<script lang="ts">
  const investors = [
    {
      img: "/images/logo/crypto-capital.png",
      title: "Crypto.com Capital",
      link: "https://crypto.com/capital",
    },
    {
      img: "/images/logo/framework-ventures.png",
      title: "Framework Ventures",
      link: "https://framework.ventures/",
    },
    {
      img: "/images/logo/LH-ventures.png",
      title: "HashKeyCapital",
      link: "https://www.hashkeycap.com/",
    },
    {
      img: "/images/logo/mark-cuban-companies.png",
      title: "MarkCubanCompanies",
      link: "https://markcubancompanies.com/",
    },
    {
      img: "/images/logo/mechanism-capital.png",
      title: "Mechanism Capital",
      link: "https://www.mechanism.capital/",
    },
    {
      title: "And others...",
      link: "https://medium.com/alphawallet/alchemynft-raises-6-million-for-remix-platform-to-autograph-nfts-78c7a47228d1",
    },
  ];
</script>

{#each investors as investor, index}
  <a
    class="bg-black-22 hover:bg-black-33 rounded-lg  h-28  items-center flex justify-center sm:mb-4 "
    href={investor.link}
    target="_blank"
  >
    {#if investor.img}
      <img
        src={investor.img}
        alt={investor.title}
        class="mx-auto phone:w-4/5  {index === 4
          ? 'md:max-h-20 md:w-auto'
          : 'md:w-3/5 '}"
      />
    {:else}
      <span class="uppercase font-bold text-2xl font-title"
        >{investor.title}</span
      >
    {/if}
  </a>
{/each}
